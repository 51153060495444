// src/App.js
import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Chat from './Pages/chat';
import Home from './Pages/home';
import Register from './Pages/register';
import Personality_Test from './Pages/personality_test';
import Ranking from './Pages/ranking';
import Quiz from './Pages/Quiz';
import QuizChoice from './Pages/quiz_choice';
import Explore from './Pages/explore';
import ChatList from './Pages/chat_list';
import Character from './Pages/character';
import CharacterQuiz from './Pages/CharacterQuiz';

function App() {

  return (
    <BrowserRouter>
      <Routes class="route">
        <Route path="/" exact element={<Home />} />
        <Route path="/Test" element={<Personality_Test />} />
        <Route path="/Quiz" element={<QuizChoice />} />
        <Route path="/Quiz/:media_id" element={<Quiz />} />
        <Route path="/Ranking" element={<Ranking />} />
        <Route path="/Chat/:char_id" element={<Chat />} />
        <Route path="/ChatList" element={<ChatList />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Character/:char_id" element={<Character />} />
        <Route path="/CharacterQuiz/:char_id" element={<CharacterQuiz />} />
      </Routes>
      <Navbar />
    </BrowserRouter>
  );
}

export default App;
