import React, { useEffect, useState } from 'react';
import api from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import CharacterQuestions from '../Components/CharacterQuestions';
import MangaQuestions from '../Components/MangaQuestions';

// TODO: faire ce qu'il faut

export default function Quiz() {
  const { media_id } = useParams();

  const [mangas, setMangas] = useState([]);

  const [answersChara, setAnswersChara] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(`media_id ${media_id}`);
  })

  const sendData = (mangasData) => {
    let answers = {
      "character_answers": answersChara,
      "manga_answers": mangasData
    }
    api.post('/submit_votes/', JSON.stringify(answers), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${sessionStorage.getItem('Token')}`
      }
    }).then((_) => {
      navigate("/Ranking");
    }).catch((error) => {
      if (error.response.status === 403) {
        console.log("You need to be logged in to submit your answers");
        navigate("/Login");
      }
    });
  };

  const handlePass = () => {
    sendData([]);
  };

  const handleSend = (manga_ans) => {
    sendData(manga_ans);
  };


  return (
    <div>
      <header><h2>Character Quiz</h2></header>
      <div className='content'>
        <div>
          {(mangas.length <= 0) ? (
            <CharacterQuestions media_id={media_id} onUpdate={
              (ans, man) => {
                setAnswersChara(ans);
                setMangas(man);
              }
            } />
          ) : (
            <div>
              <button onClick={handlePass}>Pass</button>
              <MangaQuestions work={mangas} onUpdate={handleSend} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}