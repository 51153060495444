import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Paper, Button, Grid, Stack, Box } from "@mui/material/";
import api from "../api";
import Loader from "../Components/loader";

export default function QuizChoice() {
  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData] = useState({});
  const [emptyCategory, setEmptyCategory] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({});
  const [mediaTypes, setMediaTypes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("/quiz_choices/")
      .then((reponse) => {
        let choiceData = reponse.data;
        console.log(choiceData);
        let media = reponse.data.mangas;

        // séparer par media type

        let bymedia_type = {};
        for (let i = 0; i < media.length; i++) {
          if (media[i]["media_type"] in bymedia_type) {
            bymedia_type[media[i]["media_type"]].push(media[i]);
          } else {
            bymedia_type[media[i]["media_type"]] = [media[i]];
          }
        }
        let medTypes = choiceData.media_types.sort((a, b) =>
          a.localeCompare(b)
        );
        setMediaTypes(medTypes);

        setData(bymedia_type);
        setTabData(bymedia_type[medTypes[0]]);
        if (bymedia_type[medTypes[0]] === undefined) {
          setEmptyCategory(true);
        } else {
          setEmptyCategory(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const tabChange = (e, newValue) => {
    e.preventDefault();
    if (data[mediaTypes[newValue]] === undefined) {
      setTabData(data[mediaTypes[0]]);
      setEmptyCategory(true);
    } else {
      setTabData(data[mediaTypes[newValue]]);
      setEmptyCategory(false);
    }
    setTabValue(newValue);
  };

  const GoToQuiz = (e, work_id) => {
    e.preventDefault();
    navigate(`/Quiz/${work_id}/`);
  };

  return (
    <div>
      <header>
        <h1>Universe Voting</h1>
      </header>
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <Stack
            maxWidth={"99%"}
            spacing={2}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div>
              <p>
                Here are all the universes you can vote on. Select one to start
                voting!
              </p>
            </div>
            <Box maxWidth={"99%"}>
              <Tabs
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                value={tabValue}
                onChange={tabChange}
              >
                {mediaTypes.map((cat, index) => (
                  <Tab key={index} label={cat} />
                ))}
              </Tabs>
            </Box>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 0, sm: 2 }}
              alignItems={"center"}
              columns={{ xs: 3, sm: 8, md: 12 }}
            >
              {emptyCategory ? (
                <p>No character in this category</p>
              ) : (
                tabData.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    xs={6}
                    sm={4}
                    md={3}
                    textAlign={"center"}
                  >
                    <Paper elevation={3}>
                      <Button onClick={(e) => GoToQuiz(e, item["id"])}>
                        {item["name"]}
                      </Button>
                    </Paper>
                  </Grid>
                ))
              )}
            </Grid>
          </Stack>
        )}
      </div>
    </div>
  );
}
