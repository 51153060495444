import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api, baseURL } from '../api';

import CharacterBox from '../Components/CharacterBox';

export default function ChatList() {
  const [chatList, setChatList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    api.get("/chat/get_chatrooms/", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${sessionStorage.getItem('Token')}`
      }
    }).then((response) => {
      console.log(response.data);
      setChatList(response.data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);


  function handleClick(event, room_id) {
    event.preventDefault();
    console.log(room_id);
    navigate('/Chat/' + room_id + '/');
  }

  return (
    <div>
      <header><h2>Chat List</h2></header>
      <div className="content">
        <ol style={{ padding: '0.5em' }}>
          {chatList.length === 0 ? (
            <p>No conversation yet</p>
          ) : (
            chatList.map((item, i) => (
              <div onClick={(event) => handleClick(event, item.character.id)}>
                <CharacterBox
                  key={i}
                  char_id={item.character.id}
                  title={item.character.name}
                  room_id={item.room_id}
                  character={item.character}
                  manga={item.character.manga} />
              </div>
            ))
          )}
        </ol>
      </div>
    </div>
  );
};
