import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useRanking } from "../Components/AppContext";
import { api } from "../api";
import { CircularProgress, Box, Stack } from "@mui/material/";
import AlterEgoTab from "../Components/AlterEgoTab";
import Loader from "../Components/loader";

export default function Ranking() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const ranking = location.state?.ranking;

  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [mediaTypes, setMediaTypes] = useState([]);

  const [context, _] = useRanking();

  useEffect(() => {
    if (ranking !== undefined) {
      console.log(ranking);
      setData(ranking);
    } else if (context.length > 0) {
      console.log(context);
      setData(context);
    } else {
      api
        .get("/user_ranking", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("Token")}`,
          },
        })
        .then((response) => {
          console.log("data :");
          console.log(response.data);
          let data = response.data;
          let bymedia_type = {};
          for (let i = 0; i < data.length; i++) {
            if (data[i]["media_type"] in bymedia_type) {
              bymedia_type[data[i]["media_type"]].push(data[i]);
            } else {
              bymedia_type[data[i]["media_type"]] = [data[i]];
            }
          }
          const mediatypes = Object.keys(bymedia_type).sort((a, b) =>
            a.localeCompare(b)
          );
          setMediaTypes(mediatypes);
          setTabData(bymedia_type[mediatypes[0]]);
          setData(bymedia_type);

          console.log(mediatypes);

          setLoading(false);
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setTabValue(newValue);
    setTabData(data[mediaTypes[newValue]]);
  };

  return (
    <div>
      <header>
        <h1>Ranking</h1>
      </header>
      <div className="content">
        {loading ? (
          <Stack
            maxWidth={"100%"}
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <p>the ranking is loading, this may take up to a minute</p>
            <Loader />
          </Stack>
        ) : (
          <Stack
            maxWidth={"100%"}
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <h3>List of characters that best matches your personality!</h3>
            <Box maxWidth={"100%"}>
              {console.log("here", data)}
              {Object.keys(data).length === 0 ? (
                <p>
                  {" "}
                  please, complete your{" "}
                  <Link to="/Test">psychometry test </Link>to get characters
                  recommandations
                </p>
              ) : (
                <AlterEgoTab
                  data={data}
                  mediaTypes={mediaTypes}
                  showPercent={true}
                />
              )}
            </Box>
          </Stack>
        )}
      </div>
    </div>
  );
}
