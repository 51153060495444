import React, { useState, useEffect } from "react";
import api from "../api";
import Question from "../Model/Question";
import { useNavigate, useParams } from "react-router-dom";
import { Stack, Slider, Box, Button } from "@mui/material/";
import Loader from "../Components/loader";

export default function CharacterQuiz() {
  const char_id = useParams();
  const [values, setValues] = useState([]); // sliders values
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // Load data
    let user_psychometry = new Map();
    api
      .get(`/character_voting/${char_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${sessionStorage.getItem("Token")}`,
        },
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          user_psychometry.set(
            response.data[i]["trait"],
            response.data[i]["value"]
          );
        }
      })
      .catch((error) => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        api
          .get("/user_test/")
          .then((response) => {
            let data = response.data;
            let questionList = [];
            let valuesList = [];
            for (let i = 0; i < data.length; i++) {
              questionList.push(
                new Question(
                  data[i]["id"],
                  data[i]["keyword1"],
                  data[i]["keyword2"]
                )
              );
              if (user_psychometry.has(data[i]["id"])) {
                valuesList.push(user_psychometry.get(data[i]["id"]));
              } else {
                valuesList.push(50);
              }
            }
            setQuestions(questionList);
            setValues(valuesList);
            if (questionList.length > 0) {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(`error ${error}`);
          });
      });
  }, []);

  const handleChange = (index, newValues) => {
    const newSliderValues = [...values];
    newSliderValues[index] = newValues;
    setValues(newSliderValues);
  };

  const sendData = (answers) => {
    api
      .post("/submit_votes/", JSON.stringify(answers), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${sessionStorage.getItem("Token")}`,
        },
      })
      .then((_) => {
        navigate("/Ranking");
      })
      .catch((error) => {
        if (error.response.status === 403) {
          console.log("You need to be logged in to submit your answers");
          navigate("/Login");
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let answer = [];
    const vals = values;
    const quest = questions;
    for (let i = 0; i < quest.length; i++) {
      answer.push({
        trait: quest[i].no,
        value: 100 - parseInt(vals[i]) + 1,
      });
    }
    console.log("answer");
    console.log(answer);
    let header = {
      "Content-Type": "application/json",
    };
    if (sessionStorage.getItem("Token")) {
      header["Authorization"] = `Token ${sessionStorage.getItem("Token")}`;
      api
        .post("/submit_test/", JSON.stringify(answer), {
          headers: header,
        })
        .then((response) => {
          navigate("/Ranking");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      api
        .post("/ranking/", JSON.stringify(answer), {
          headers: header,
        })
        .then((response) => {
          const data = response.data;
          navigate("/Ranking", { state: { ranking: data } });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <header>
        <h2>Character Personality Test</h2>
      </header>
      <div className="content">
        {true ? (
          <p>Coming Soon...</p>
        ) : loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              {questions.map((quest, index) => (
                <Box key={index}>
                  <i>is this character more :</i>
                  <Slider
                    style={{ width: "80%" }}
                    marks={[
                      { value: 1, label: quest.kw1 },
                      { value: 100, label: quest.kw2 },
                    ]}
                    track={false}
                    min={1}
                    max={100}
                    value={index}
                    name="trait"
                    onChange={(e, newVal) => handleChange(e, index, newVal)}
                  />
                </Box>
              ))}
              <Button type="submit"> Finished </Button>
            </Stack>
          </form>
        )}
      </div>
    </div>
  );
}
