// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav .link-styles {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    color: white;
    border: rgba(0, 0, 0, 0.08) solid 1px;
    width: 100%;
}

.nav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    height: 10vh;
    background-color: var(--primary-color);
}

.nav .link-styles:hover {
    background-color:aqua;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,4BAA4B;IAC5B,YAAY;IACZ,qCAAqC;IACrC,WAAW;AACf;;AAEA;IACI,eAAe;IACf,SAAS;IACT,QAAQ;IACR,OAAO;IACP,aAAa;IACb,mBAAmB;IACnB,4BAA4B;IAC5B,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".nav .link-styles {\n    align-items: center;\n    display: flex;\n    flex-direction: row;\n    justify-content:space-evenly;\n    color: white;\n    border: rgba(0, 0, 0, 0.08) solid 1px;\n    width: 100%;\n}\n\n.nav {\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    display: flex;\n    flex-direction: row;\n    justify-content:space-evenly;\n    height: 10vh;\n    background-color: var(--primary-color);\n}\n\n.nav .link-styles:hover {\n    background-color:aqua;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
