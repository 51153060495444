import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { api, baseURL } from "../api";
import {
  Tabs,
  Tab,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Grid,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FaAmazon, FaApple } from "react-icons/fa";
import { Tv } from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material/";
import { SiNetflix, SiRoku } from "react-icons/si";
import AlterEgoTab from "../Components/AlterEgoTab";
import Loader from "../Components/loader";

const platformIcons = {
  netflix: <SiNetflix />,
  amazon: <FaAmazon />,
  appletv: <FaApple />,
  roku: <SiRoku />,
  paramountplus: <Tv />,
  fubo: <Tv />,
};

export default function CharacterPage() {
  const [tabValue, setTabValue] = useState(0);
  const [mediaTypes, setMediaTypes] = useState([]);
  const [rankingData, setRankingData] = useState([]);
  const { char_id } = useParams();
  const [character, setCharacter] = useState({
    name: "Loading...",
    manga: "",
    img_url: `${baseURL}/images/characters/${char_id}.jpg`,
    stats: [{ keyword: "Loading keywords...", value: 0, nb_votes: 0 }],
    loaded: false,
  });
  const [mediaData, setMediaData] = useState([]);

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    api
      .get(`/character/${char_id}`)
      .then((response) => {
        const data = response.data.character;
        const chara = {
          name: data.name,
          manga: data.manga,
          id: data.id,
          img_url: `${baseURL}/character_picture/${char_id}/`,
          stats: data.stats,
          loaded: true,
        };
        setCharacter(chara);

        let rank = data.alter_ego;
        let bymedia_type = {};
        for (let i = 0; i < rank.length; i++) {
          if (rank[i]["name"] === chara.name) {
            continue;
          }
          if (rank[i]["media_type"] in bymedia_type) {
            bymedia_type[rank[i]["media_type"]].push(rank[i]);
          } else {
            bymedia_type[rank[i]["media_type"]] = [rank[i]];
          }
        }
        const mediatypes = Object.keys(bymedia_type).sort((a, b) =>
          a.localeCompare(b)
        );
        setMediaTypes(mediatypes);
        setRankingData(bymedia_type);
      })
      .catch((error) => {
        console.log(`error ${error}`);
      });

    fetch("/JSON with plateform names.json")
      .then((response) => response.json())
      .then((data) => {
        setMediaData(data.medias);
      })
      .catch((error) => {
        console.log(`Error fetching media JSON: ${error}`);
      });
  }, [char_id]);

  const tabChange = (e, newValue) => {
    e.preventDefault();
    setTabValue(newValue);
  };

  const voteRedirect = (e, char_id) => {
    e.preventDefault();
    navigate(`/CharacterQuiz/${char_id}/`);
  };

  const getMediaLinks = () => {
    if (mediaData.length === 0) return null;
    const characterMedia = mediaData?.find(
      (media) => media.name === character.manga
    );
    if (!characterMedia) return null;

    return (
      <div>
        <h3>Available on:</h3>
        <List>
          {characterMedia.url.map((platform, index) => (
            <ListItem key={index}>
              {Object.keys(platform).map((key) => (
                <Link
                  href={platform[key]}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={key}
                  underline="none"
                  style={{
                    display: "flex",
                    gap: "1.5em",
                    alignItems: "center",
                    marginRight: "40px",
                  }}
                >
                  <ListItemIcon>
                    <img
                      style={{ width: "2.5em", borderRadius: "1em" }}
                      src={`/Platform images/${key.toLowerCase()}.jpg`}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={key.charAt(0).toUpperCase() + key.slice(1)}
                    style={{ marginLeft: "-1.8em" }}
                  />
                </Link>
              ))}
            </ListItem>
          ))}
        </List>
        <div>
          <h3 style={{ marginBottom: "0.5em" }}>Media Type : </h3>{" "}
          <span style={{ marginLeft: "1.1em" }}>
            {characterMedia.media_type}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <header>
        <h2>Character Info</h2>
      </header>
      <div className="content">
        {character ? (
          <div
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              gap: isSmallScreen ? "2em" : "7em",
            }}
          >
            <div>
              <Stack direction="row" spacing={2}>
                {character.loaded ? (
                  <img
                    src={character.img_url}
                    alt={`${character.name} image`}
                    style={{
                      width: isSmallScreen ? "100%" : "5em",
                      borderRadius: "1.5em",
                    }}
                  />
                ) : (
                  <Loader />
                )}
                <Stack direction="column" spacing={2} justifyContent="center">
                  <h1>{character.name}</h1>
                  <p>From {character.manga}</p>
                </Stack>
              </Stack>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {getMediaLinks()}
            </div>
          </div>
        ) : (
          <Loader />
        )}
        <Tabs
          value={tabValue}
          onChange={tabChange}
          variant={isSmallScreen ? "scrollable" : "standard"}
        >
          <Tab label="Stats" />
          <Tab label="AlterEgo" />
        </Tabs>
        {character.loaded && (
          <Box sx={{ width: "50%" }}>
            {tabValue === 0 ? (
              <Stack direction="column" spacing={2} alignItems="center">
                <p />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Trait</b>
                      </TableCell>
                      <TableCell>
                        <b>Value</b>
                      </TableCell>
                      <TableCell>
                        <b>Nb Votes</b>
                      </TableCell>
                      <TableCell>
                        <b>Score Deviation</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {character.stats.map((stat) => {
                      const keyword =
                        stat.value < 50 ? stat.keyword1 : stat.keyword2;
                      let adjustedValue = stat.value;
                      if (stat.value < 50) adjustedValue = 100 - stat.value;

                      return (
                        <TableRow key={stat.id}>
                          <TableCell>{keyword}</TableCell>
                          <TableCell>{Math.round(adjustedValue)}</TableCell>
                          <TableCell>{stat.nb_votes}</TableCell>
                          <TableCell>{stat.sd}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2">
                    You don't agree with these values? Vote for the character's
                    personality trait!
                  </Typography>
                  <Button onClick={(e) => voteRedirect(e, character)}>
                    Vote
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <AlterEgoTab
                data={rankingData}
                mediaTypes={mediaTypes}
                showPercent={true}
              />
            )}
          </Box>
        )}
      </div>
    </div>
  );
}
