
import React, { useState } from 'react';

export default function CharacterQuestions({ work, onUpdate }) {
    const [formData, setFormData] = useState({});

    const handleFormSubmit = (event) => {
        event.preventDefault();
        let answers = { pref: {}, know: {} };
        for (const [key, value] of Object.entries(formData)) {
            let [type, id] = key.split(" ");
            answers[type][id] = parseInt(value);
        }

        console.log(answers);

        onUpdate(answers);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <div>
                <div>
                    <h3> - {work.name} - </h3>
                    <p><b>How much do you know of {work.name} ? </b></p>
                    <p><label><input name={`know ${work.id}`} type="radio" value={0} onChange={handleInputChange} /> Never watched it</label></p>
                    <p><label><input name={`know ${work.id}`} type="radio" value={1} onChange={handleInputChange} /> I watched it once a long time ago</label></p>
                    <p><label><input name={`know ${work.id}`} type="radio" value={2} onChange={handleInputChange} /> I watched it recently</label></p>
                    <p><label><input name={`know ${work.id}`} type="radio" value={3} onChange={handleInputChange} /> I watched it many times</label></p>
                    <p><label><input name={`know ${work.id}`} type="radio" value={4} onChange={handleInputChange} /> I know it by heart</label></p>

                    <p><b>How would you rate {work.name} ? </b></p>
                    <p><label><input name={`pref ${work.id}`} type="radio" value={0} onChange={handleInputChange} /> No opinion </label></p>
                    <p><label><input name={`pref ${work.id}`} type="radio" value={1} onChange={handleInputChange} /> Very bad</label></p>
                    <p><label><input name={`pref ${work.id}`} type="radio" value={2} onChange={handleInputChange} /> Could do better</label></p>
                    <p><label><input name={`pref ${work.id}`} type="radio" value={3} onChange={handleInputChange} /> Good </label></p>
                    <p><label><input name={`pref ${work.id}`} type="radio" value={4} onChange={handleInputChange} /> Very good </label></p>
                    <p><label><input name={`pref ${work.id}`} type="radio" value={5} onChange={handleInputChange} /> A masterpiece </label></p>

                </div>
                <button type="submit" onClick={handleFormSubmit}>
                    Send
                </button>
            </div>
        </form>
    );
}

