import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Stack } from "@mui/material/";
import { Button, TextField } from "@mui/material/";

export default function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordValidationChange = (event) => {
    setPasswordValidation(event.target.value);
  };

  const handleRegister = () => {
    if (password === passwordValidation) {
      api
        .post(
          "/signup/",
          JSON.stringify({ username: username, password: password }),
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          sessionStorage.setItem("Token", response.data.token);
          navigate("/");
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setInvalidUsername(true);
          }
        });
    } else {
      // Show error message or perform validation logic here
      console.log("Passwords do not match");
      setInvalidPassword(true);
    }
  };

  return (
    <div className="content">
      <Stack spacing={2}>
        <h2 style={{ color: "black" }}>Register</h2>
        <TextField
          error={invalidUsername}
          type="text"
          label="Username"
          value={username}
          onChange={handleUsernameChange}
          helperText={invalidUsername ? "Username already exists!" : ""}
        />
        <TextField
          type="password"
          label="Password"
          value={password}
          error={invalidPassword}
          onChange={handlePasswordChange}
        />
        <TextField
          type="password"
          label="Confirm Password"
          value={passwordValidation}
          error={invalidPassword}
          helperText={invalidPassword ? "Passwords do not match!" : ""}
          onChange={handlePasswordValidationChange}
        />
        <Button onClick={handleRegister}>Register</Button>
      </Stack>
    </div>
  );
}
