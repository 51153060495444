import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../api";
import { Box } from "@mui/material/";
import AlterEgoTab from "../Components/AlterEgoTab";
import Loader from "../Components/loader";

export default function Explore() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const logged = sessionStorage.getItem("Token") !== null;
  const [mediaTypes, setMediaTypes] = useState([]);

  useEffect(() => {
    if (logged) {
      api
        .get("/explore", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("Token")}`,
          },
        })
        .then((response) => {
          let data = response.data;
          let bymedia_type = {};
          for (let i = 0; i < data.length; i++) {
            if (data[i]["media_type"] in bymedia_type) {
              bymedia_type[data[i]["media_type"]].push(data[i]);
            } else {
              bymedia_type[data[i]["media_type"]] = [data[i]];
            }
          }
          const mediatypes = Object.keys(bymedia_type).sort((a, b) =>
            a.localeCompare(b)
          );
          setMediaTypes(mediatypes);
          setData(bymedia_type);
          setLoading(false);
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <header>
        <h1>Explore</h1>
      </header>
      <div className="content">
        {!logged ? (
          <div>
            <p> You must be logged to access this feature. </p>
            <Link to="/"> I want to login ! </Link>
          </div>
        ) : (
          <div>
            <h3>Discover characters from anime you may like</h3>
            <div>
              {loading ? (
                <Loader />
              ) : (
                <Box>
                  <AlterEgoTab
                    data={data}
                    mediaTypes={mediaTypes}
                    showPercent={true}
                  />
                </Box>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
