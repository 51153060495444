import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

export function useRanking(){
    return useContext(AppContext);
}

export function ContextProvider({ children }) {
    const [ranking, setRanking] = useState([]);

    function updateRanking() {
        return (newRanking) => {
            setRanking(newRanking);
        }
    }

    return (
        <AppContext.Provider value={[ranking, updateRanking]}>
            {children}
        </AppContext.Provider>
    );
}
