import React from "react";
import {
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Stack, Box } from "@mui/material";
import { useState, useEffect } from "react";
import CharacterBox from "./CharacterBox";

export default function AlterEgoTab({ data, mediaTypes, showPercent = false }) {
  const ShowPercent = showPercent;
  const MediaTypes = mediaTypes;
  const [currentTab, setCurrentTab] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [emptyCategory, setEmptyCategory] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data[MediaTypes[currentTab]] === undefined) {
      setTabData(data[MediaTypes[0]]);
      setEmptyCategory(true);
    } else {
      setTabData(data[MediaTypes[currentTab]]);
      setEmptyCategory(false);
    }
  }, [currentTab, data, MediaTypes]);
  useEffect(() => {
    if (selectedMovie) {
      setFilteredData(tabData.filter((item) => item.manga === selectedMovie));
    } else {
      setFilteredData(tabData);
    }
  }, [selectedMovie, tabData]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setSelectedMovie(""); // Reset movie selection on tab change
  };

  const handleMovieChange = (event) => {
    setSelectedMovie(event.target.value);
  };

  const getUniqueMovies = () => {
    const movies = tabData.map((item) => item.manga);
    return [...new Set(movies)];
  };
  console.log(data);

  return (
    <Stack spacing={2} direction="column" maxWidth={"100%"}>
      <Box>
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={currentTab}
          onChange={handleTabChange}
        >
          {MediaTypes.map((type, index) => (
            <Tab key={index} label={type} />
          ))}
        </Tabs>
      </Box>
      <Box>
        <FormControl fullWidth>
          <InputLabel id="movie-select-label"></InputLabel>
          <Select
            labelId="movie-select-label"
            value={selectedMovie}
            onChange={handleMovieChange}
            displayEmpty
          >
            <MenuItem key={"b"} value="">
              <em> {"   "}All</em>
            </MenuItem>
            {getUniqueMovies().map((manga, index) => (
              <MenuItem key={index} value={manga}>
                {manga}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Stack spacing={2}>
        {emptyCategory ? (
          <p>No characters in this category</p>
        ) : (
          filteredData.map((item, index) => (
            <CharacterBox
              key={index}
              char_id={item.id}
              title={
                ShowPercent
                  ? `${item.name} - ${Math.round(item.score * 1000) / 10}%`
                  : item.name
              }
              room_id={item.room_id}
              character={item}
              manga={item.manga}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
}
