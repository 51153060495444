import React, { useState, useEffect } from "react";
import { api, baseURL } from "../api";
import Question from "../Model/Question";
import Character from "../Model/Character";
import { Stack, Button, Slider, Typography } from "@mui/material/";
import { Card, CardMedia, CardContent, CardActions } from "@mui/material/";
import ArrowLeftRounded from "@mui/icons-material/ArrowLeftRounded";
import Loader from "./loader";

export default function CharacterQuestions({ onUpdate, media_id }) {
  const [traitValues, setTraitValues] = useState([]); // sliders values
  const [pondValues, setPondValues] = useState([]); // sliders values
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [work, setWork] = useState([]);

  useEffect(() => {
    // Load data
    api
      .get(`/character_quiz/${media_id}/`)
      .then((response) => {
        let data = response.data["questions"];
        console.log(data);
        setWork(response.data["work"]);
        let questionList = [];
        for (let i = 0; i < data.length; i++) {
          const question = data[i]["trait"];
          const character = data[i]["character"];
          questionList.push({
            question: new Question(
              question["id"],
              question["keyword1"],
              question["keyword2"]
            ),
            character: new Character(
              character["name"],
              character["manga"],
              character["id"]
            ),
          });
        }
        // shuffle the questions
        questionList.sort(() => Math.random() - 0.5);
        setQuestions(questionList);
        let valuesList = [];
        let pondList = [];
        for (let i = 0; i < questionList.length; i++) {
          valuesList.push(50);
          pondList.push(50);
        }
        setTraitValues(valuesList);
        setPondValues(pondList);
        if (questionList.length > 0) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(`error ${error}`);
      });
  }, []);

  const handleChangeTraits = (e, index, newValue) => {
    e.preventDefault();
    const newSliderValues = [...traitValues];
    newSliderValues[index] = newValue;
    setTraitValues(newSliderValues);
  };

  const handleChangePond = (e, index, newValues) => {
    e.preventDefault();
    const newSliderValues = [...pondValues];
    newSliderValues[index] = newValues;
    setPondValues(newSliderValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let answer = [];
    const vals = traitValues;
    const quest = questions;
    for (let i = 0; i < quest.length; i++) {
      const data = {
        character: quest[i]["character"].id,
        trait: quest[i]["question"].no,
        value: vals[i],
        pond: pondValues[i],
      };
      answer.push(data);
    }
    onUpdate(answer, work);
  };

  const removeQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
    const newValues = [...traitValues];
    newValues.splice(index, 1);
    setTraitValues(newValues);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {questions.map((quest, index) => (
              <Card key={index}>
                <CardMedia>
                  <img
                    src={`${baseURL}/character_picture/${quest["character"].id}/`}
                    alt={quest["character"].name}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "14em",
                      borderRadius: "2.5em",
                    }}
                  />
                </CardMedia>
                <CardContent>
                  <Stack spacing={1} alignItems={"center"}>
                    <Stack>
                      <h3>Is {quest["character"].name}</h3>
                      <i> from {quest["character"].manga} </i>
                    </Stack>
                    <Slider
                      style={{ width: "80%" }}
                      marks={[
                        { value: 1, label: quest["question"].kw1 },
                        { value: 100, label: quest["question"].kw2 },
                      ]}
                      track={false}
                      min={1}
                      max={100}
                      value={traitValues[index]}
                      name="trait"
                      onChange={(e, newVal) =>
                        handleChangeTraits(e, index, newVal)
                      }
                    />
                    <p />
                    <p />
                    <p>
                      {" "}
                      how relevant is this trait to {quest["character"].name}'s
                      personality?{" "}
                    </p>
                    <Slider
                      style={{ width: "80%" }}
                      marks={[
                        { value: 1, label: "little" },
                        { value: 100, label: "much" },
                      ]}
                      track={false}
                      min={1}
                      max={100}
                      value={pondValues[index]}
                      name="trait"
                      onChange={(e, newVal) =>
                        handleChangePond(e, index, newVal)
                      }
                    />
                    <p />
                  </Stack>
                </CardContent>
                <CardActions>
                  <Button onClick={() => removeQuestion(index)}>Skip</Button>
                  <ArrowLeftRounded />
                  <Typography variant="caption" gutterBottom>
                    Not inspired ? you can skip this one
                  </Typography>
                </CardActions>
              </Card>
            ))}

            <Button type="submit"> Finished </Button>
          </Stack>
        </form>
      )}
    </div>
  );
}
