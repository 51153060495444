// Navbar.js
import React from 'react';
import { BsFillPersonFill, BsFillPieChartFill, BsFillPersonLinesFill, BsFillChatFill, BsFillStarFill } from 'react-icons/bs';
import { FaRankingStar } from 'react-icons/fa6';
import "./Navbar.css"
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav>
      <div className={'nav'}>
        <Link className="link-styles" to="/"> <BsFillPersonFill /> </Link>
        <Link className="link-styles" to="/Test"> <BsFillPieChartFill /> </Link>
        <Link className="link-styles" to="/Quiz"> <BsFillPersonLinesFill /> </Link>
        <Link className="link-styles" to="/Ranking"> <FaRankingStar /> </Link>
        <Link className="link-styles" to="/ChatList"> <BsFillChatFill /> </Link>
      </div>
    </nav>
  );
}

/*
BsFillLockFill
BsFillPieChartFill
BsFillPersonLinesFill
FaRankingStar
BsFillChatFill
*/

export default Navbar;
