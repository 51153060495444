import React, { useState, useEffect } from "react";
import PersonalitySlider from "../Components/PersonalitySlider";
import api from "../api";
import Question from "../Model/Question";
import { useNavigate } from "react-router-dom";
import { useRanking } from "../Components/AppContext";
import Loader from "../Components/loader";

export default function Personality_Test() {
  const [values, setValues] = useState([]); // sliders values
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const [_, rankingUpdate] = useRanking();

  const navigate = useNavigate();

  useEffect(() => {
    // Load data
    let user_psychometry = new Map();
    api
      .get("/user_psychometry/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${sessionStorage.getItem("Token")}`,
        },
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          user_psychometry.set(
            response.data[i]["trait"],
            100 - response.data[i]["value"] + 1
          );
        }
      })
      .catch((error) => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        api
          .get("/user_test/")
          .then((response) => {
            let data = response.data;
            let questionList = [];
            let valuesList = [];
            for (let i = 0; i < data.length; i++) {
              questionList.push(
                new Question(
                  data[i]["id"],
                  data[i]["keyword1"],
                  data[i]["keyword2"]
                )
              );
              if (user_psychometry.has(data[i]["id"])) {
                valuesList.push(user_psychometry.get(data[i]["id"]));
              } else {
                valuesList.push(50);
              }
            }
            setQuestions(questionList);
            setValues(valuesList);
            if (questionList.length > 0) {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(`error ${error}`);
          });
      });
  }, []);

  const handleChange = (index, newValues) => {
    const newSliderValues = [...values];
    newSliderValues[index] = newValues;
    setValues(newSliderValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let answer = [];
    const vals = values;
    const quest = questions;
    for (let i = 0; i < quest.length; i++) {
      answer.push({
        trait: quest[i].no,
        value: 100 - parseInt(vals[i]) + 1,
      });
    }
    console.log("answer");
    console.log(answer);
    let header = {
      "Content-Type": "application/json",
    };
    if (sessionStorage.getItem("Token")) {
      header["Authorization"] = `Token ${sessionStorage.getItem("Token")}`;
      api
        .post("/submit_test/", JSON.stringify(answer), {
          headers: header,
        })
        .then((response) => {
          navigate("/Ranking");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      api
        .post("/ranking/", JSON.stringify(answer), {
          headers: header,
        })
        .then((response) => {
          const data = response.data;
          rankingUpdate()(data);
          navigate("/Ranking", { state: { ranking: data } });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <header>
        <h2>User Personality Test</h2>
      </header>
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            {questions.map((quest, index) => (
              <div className="PersoSlider" key={index}>
                <i>are you more</i>
                <label>
                  <b>{quest.kw1}</b> or <b>{quest.kw2}</b>
                </label>
                <PersonalitySlider
                  value={values[index]}
                  onChange={(e) => handleChange(index, e.target.value)}
                />
              </div>
            ))}
            <button type="submit"> Finished </button>
          </form>
        )}
      </div>
    </div>
  );
}
