// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: rgb(0, 0, 0);
  --body_font: Arial, Helvetica, sans-serif;
  --title_font: "Trebuchet MS", Helvetica, sans-serif;
  --devfont: "Comic Sans MS";
}

body {
  font-family: var(--body_font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title_font);
  margin: 2px;
}

h1,
h2 {
  color: white;
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 10vh;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  position: fixed;
  top: 10vh;
  right: 0;
  left: 0;
  bottom: 10vh;
  overflow-y: scroll;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PersoSlider {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,yCAAyC;EACzC,mDAAmD;EACnD,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;;;;;;EAME,8BAA8B;EAC9B,WAAW;AACb;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,OAAO;EACP,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,OAAO;EACP,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[":root {\n  --primary-color: rgb(0, 0, 0);\n  --body_font: Arial, Helvetica, sans-serif;\n  --title_font: \"Trebuchet MS\", Helvetica, sans-serif;\n  --devfont: \"Comic Sans MS\";\n}\n\nbody {\n  font-family: var(--body_font);\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-family: var(--title_font);\n  margin: 2px;\n}\n\nh1,\nh2 {\n  color: white;\n}\n\nheader {\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  height: 10vh;\n  padding-left: 5vw;\n  padding-right: 5vw;\n  background-color: var(--primary-color);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.content {\n  position: fixed;\n  top: 10vh;\n  right: 0;\n  left: 0;\n  bottom: 10vh;\n  overflow-y: scroll;\n  padding: 1em;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.PersoSlider {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 5vh;\n  margin-bottom: 5vh;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
