import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../api";
import { Stack, Box, Typography, TextField } from "@mui/material/";
import { FormControl, Button, Input, InputLabel } from "@mui/material/";

export default function Home() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [logged, setLogged] = useState(
    sessionStorage.getItem("Token") !== null
  );

  useEffect(() => {
    if (sessionStorage.getItem("Token") !== null) {
      setLogged(true);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    api
      .post(
        "/login/",
        JSON.stringify({ username: username, password: password }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        sessionStorage.setItem("Token", response.data.token);
        window.location.reload(); // Rafraîchir la page
      })
      .catch((error) => {
        console.log(`error ${error}`);
      });
  };

  return (
    <div>
      <header>
        <h1>Fantheon</h1>
      </header>
      <Box className="content">
        <h2 style={{ color: "black" }}> Welcome to Fantheon !</h2>
        <h3>What can you do here ?</h3>
        <p />
        <h4>
          Fill your personality test and get the ranking of your most alike
          characters !
        </h4>
        <Link to="/Test"> I want to fill my personality test ! </Link>
        <p />
        {!logged ? (
          <Stack spacing={2}>
            <h4>Login to access more features !</h4>
            <Stack spacing={1} component="form" onSubmit={handleSubmit}>
              <TextField
                type="text"
                label="Username"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                type="password"
                label="Password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button type="submit">Login</Button>
            </Stack>
            <Link to="/Register"> I don't have an account ! </Link>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems={"flex-start"}>
            <h4>
              Vote for the personality traits of your favourite characters!
            </h4>
            <Link to="/Quiz">
              {" "}
              I want to share my knowledge about my favourite characters !{" "}
            </Link>
            <Button
              onClick={() => {
                sessionStorage.clear();
                window.location.reload();
              }}
            >
              Logout
            </Button>
          </Stack>
        )}
      </Box>
    </div>
  );
}
