// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="range"] {
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
}



:root {
    --slider-track-color: #c6c6c6;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
    background: var(--slider-track-color);
    height: 0.4rem;
    border-radius: 5rem;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
    background: var(--slider-track-color);
    height: 0.4rem;
    border-radius: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/PersonalitySlider.css"],"names":[],"mappings":"AAAA;IAEI,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;IACf,YAAY;AAChB;;;;AAIA;IACI,6BAA6B;AACjC;;AAEA,qDAAqD;AACrD;IACI,qCAAqC;IACrC,cAAc;IACd,mBAAmB;AACvB;;AAEA,0BAA0B;AAC1B;IACI,qCAAqC;IACrC,cAAc;IACd,mBAAmB;AACvB","sourcesContent":["input[type=\"range\"] {\n    -webkit-appearance: none;\n    appearance: none;\n    background: transparent;\n    cursor: pointer;\n    width: 15rem;\n}\n\n\n\n:root {\n    --slider-track-color: #c6c6c6;\n}\n\n/***** Chrome, Safari, Opera, and Edge Chromium *****/\ninput[type=\"range\"]::-webkit-slider-runnable-track {\n    background: var(--slider-track-color);\n    height: 0.4rem;\n    border-radius: 5rem;\n}\n\n/******** Firefox ********/\ninput[type=\"range\"]::-moz-range-track {\n    background: var(--slider-track-color);\n    height: 0.4rem;\n    border-radius: 5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
