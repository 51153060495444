// PersonalitySlider.js
import React from 'react';
import "./PersonalitySlider.css";

function PersonalitySlider({value, onChange }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <input
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default PersonalitySlider;
