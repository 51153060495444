import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./chat.css";
import api from "../api";
import { baseURL } from "../api";
import {
  CircularProgress,
  Box,
  Stack,
  Card,
  CardContent,
  Typography,
  CardMedia,
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
  Drawer,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../Components/loader";

export default function Chat() {
  const { char_id } = useParams();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msgLoading, setMsgLoading] = useState(false);
  const [character, setCharacter] = useState({});
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [personality, setPersonality] = useState("");
  const [scenerio, setScenerio] = useState("");
  const [description, setDescription] = useState("");
  const [example_conversation1, setExample_conversation1] = useState("");
  const [example_conversation2, setExample_conversation2] = useState("");
  const [example_conversation3, setExample_conversation3] = useState("");

  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function safeJsonParse(str) {
    try {
      const jsonString = str.replace(/'/g, '"'); // Replace single quotes with double quotes
      return JSON.parse(jsonString);
    } catch (e) {
      return str;
    }
  }

  function processText(text) {
    const parsedText = safeJsonParse(text);
    const message =
      typeof parsedText === "object" && parsedText.message
        ? parsedText.message
        : text;
    const parts = String(message).split(/(\*[^*]+\*)/);
    return parts.map((part, index) =>
      part.startsWith("*") && part.endsWith("*") ? (
        <em key={index}>{part.slice(1, -1)}</em>
      ) : (
        part
      )
    );
  }

  useEffect(() => {
    // Load character data
    api
      .get(`/character/${char_id}`)
      .then((response) => {
        let data = response.data.character;
        const chara = {
          name: data.name,
          manga: data.manga,
          id: data.id,
          img_url: `${baseURL}/character_picture/${char_id}/`,
        };
        setCharacter(chara);
      })
      .catch((error) => {
        console.error("Error fetching character data:", error);
        setLoading(false);
      });
  }, [char_id]);

  useEffect(() => {
    if (character.id) {
      setMsgLoading(true);
      api
        .get(`chat/chatroom/${char_id}/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("Token")}`,
          },
        })
        .then((response) => {
          let msgList = response.data.messages.map((msg) => ({
            role: msg.role,
            text: msg.content,
          }));
          setMessages(msgList);
          setDescription(response.data.card[0].description);
          setPersonality(response.data.card[0].personality);
          setScenerio(response.data.card[0].scenario);
          setExample_conversation1(response.data.card[0].example_conversation1);
          setExample_conversation2(response.data.card[0].example_conversation2);
          setExample_conversation3(response.data.card[0].example_conversation3);
          setLoading(false);
          setMsgLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            console.log("Not logged in");
          }
          setLoading(false);
        });
    }
  }, [char_id, character.id]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMsg = () => {
    const newMsg = inputMessage;
    if (!newMsg.trim()) {
      return;
    }

    const newMessages = [...messages, { role: "user", text: newMsg }];
    setMessages(newMessages);
    setInputMessage("");

    let header = {
      "Content-Type": "application/json",
    };
    let route = `/chat/send_message/${char_id}/`;
    if (sessionStorage.getItem("Token")) {
      header["Authorization"] = `Token ${sessionStorage.getItem("Token")}`;
      route = `/chat/send_message_auth/${char_id}/`;
    }

    setMsgLoading(true);
    api
      .post(route, { message: newMsg }, { headers: header })
      .then((response) => {
        let answer = response.data;
        setMessages([...newMessages, { role: "bot", text: answer }]);
        setMsgLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setMessages([
          ...newMessages,
          { role: "bot", text: "There was an error, please try again." },
        ]);
        setMsgLoading(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMsg();
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const characterCard = (
    <Card sx={{ minWidth: 150, marginBottom: 2 }}>
      <CardMedia
        component="img"
        height="300"
        image={character.img_url}
        alt={`${character.name} image`}
        sx={{ objectFit: "cover" }}
      />
      <CardContent>
        <Typography color="text.secondary">
          <strong>Character name :</strong>
          <br />
          {character.name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <strong>Universe :</strong>
          <br />
          {character.manga}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <strong>Character description :</strong>
          <br />
          {description}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <strong>Character personality :</strong>
          <br />
          {personality}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <strong>Scenario :</strong>
          <br />
          {scenerio}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <strong>Potential Conversation Starter 1</strong>
          <br />
          {example_conversation1}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <strong>Potential Conversation Starter 2</strong>
          <br />
          {example_conversation2}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <strong>Potential Conversation Starter 3</strong>
          <br />
          {example_conversation3}
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <div>
      <header>
        {!loading &&
          (isSmallScreen ? (
            <>
              <div style={{ display: "flex" }}>
                <img
                  src={`${character.img_url}`}
                  height={"50px"}
                  width={"50px"}
                  style={{ borderRadius: 100 + "%" }}
                />
                <h4
                  style={{
                    marginLeft: 1 + "rem",
                    color: "white",
                    marginTop: 1 + "rem",
                    fontSize: "large",
                  }}
                >{`${character.name}`}</h4>

                <IconButton
                  onClick={handleDrawerToggle}
                  style={{
                    color: "white",
                    borderTop: 1,
                    borderColor: "white",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",
                      right: 8 + "%",
                      paddingTop: 5 + "px",
                    }}
                  >
                    <MenuIcon color="white" />
                  </div>
                </IconButton>
              </div>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                sx={{
                  "& .MuiDrawer-paper": {
                    width: "100%",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",

                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <IconButton
                    onClick={handleDrawerToggle}
                    sx={{ alignSelf: "flex-end" }}
                  >
                    <CloseIcon />
                  </IconButton>
                  {characterCard}
                </Box>
              </Drawer>
            </>
          ) : (
            <div>
              <h1 style={{ color: "white" }}>Chat</h1>
              <h5 style={{ color: "white" }}>
                You are talking to {character.name} / {character.manga}
              </h5>
            </div>
          ))}
      </header>
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        {loading ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            position={"fixed"}
            top={15 + "%"}
            right={50 + "%"}
          >
            <Loader />
          </Stack>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              {!isSmallScreen && <div className="bar">{characterCard}</div>}
            </Grid>
            <Grid item xs={12} md={9}>
              <Stack className="message-list" sx={{ flexGrow: 1 }}>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${
                      message.role === "user" ? "me" : "others"
                    }`}
                  >
                    {processText(message.text)}
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </Stack>
            </Grid>
          </Grid>
        )}
      </div>
      <div className="textInput">
        <input
          type="text"
          placeholder="Type your message here..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button onClick={sendMsg} disabled={msgLoading}>
          Send
        </button>
      </div>
    </div>
  );
}
