import axios from 'axios';


const baseURL = 'https://api.theanimatch.com'; //replace with machine ip

const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('Token');
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  }
)

export default api;
export { baseURL, api };
