import React, { useState, useEffect } from 'react';
import { BsFillPersonFill, BsFillPieChartFill, BsFillPersonLinesFill, BsFillChatFill, BsFillStarFill, BsHeart } from 'react-icons/bs';
import { baseURL } from '../api';
import { Box } from '@mui/material/';

import { useNavigate } from 'react-router-dom';

export default function CharacterBox({ char_id, character, title, name, manga, room_id }) {

    useEffect(() => {
        //console.log(char_id);
    })

    const navigate = useNavigate();

    const goToChat = (event) => {
        event.preventDefault();
        navigate('/Chat/' + character.id + '/');
    }

    const goToPage = (event) => {
        event.preventDefault();
        navigate('/Character/' + character.id + '/');
    }

    return (
        <Box
            style={{
                display: 'block',
                borderRadius: '0',
                border: 'none',
                padding: '1em',
                margin: '0.5em',
                backgroundColor: '#f2f2f2'
            }} >
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={`${baseURL}/character_picture/${char_id}/`} alt={name} style={{ width: '3em', borderRadius: '1.5em', marginRight: '0.5em' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <h4>{title}</h4>
                        <i>from {manga}</i>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'space-between', flexDirection: 'column' }}>
                    <button onClick={(e) => goToPage(e, room_id)}><BsFillPersonFill /></button>
                    <button onClick={(e) => goToChat(e, char_id)}><BsFillChatFill /></button>
                </div>
            </div>
        </Box>
    )
}